import styled from "styled-components";
import { MdAdd, MdRemove } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { mobile } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Modal, Text } from "@nextui-org/react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { deleteProduct } from "../redux/cartRedux";
import { updateUser, updateCart, createOrder } from "../redux/apiRequests";
import { removeFromWishlist } from "../redux/userRedux";
import { Loading } from "@nextui-org/react";
import { resetOrder } from "../redux/orderRedux";
import { useTranslation } from "react-i18next";
import WhatsappImg from "../assets/whatsapp.png";

const Container = styled.div`
  max-width: 80rem;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ sidebarState }) =>
    sidebarState ? "translateX(83.5%)" : "translateX(0%)"};
`;

const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
  ${mobile({
    fontSize: "12px",
    padding: "5px",
    margin: "0 5px",
    height: "100%",
  })}
`;
const TopTexts = styled.div`
  ${mobile({ display: "none" })}
`;

const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: ${(props) => (props.type === "cart" ? "3" : "8")};
  display: flex;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  aspect-ratio: 1.1;
  background: white;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductBrand = styled.span``;

const PriceDetail = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProductQuantity = styled.div`
  font-size: 24px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 30vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;
const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const SummaryButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;

const EmptyCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const EmptyCartText = styled.span`
  font-size: 30px;
  font-weight: 200;

  ${mobile({ fontSize: "20px", margin: "10rem 0rem" })}
`;

const ContainerCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
`;

const Select = styled.select`
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  background: #fff;
  width: 100%;

  ${mobile({
    width: "95%",
  })}
`;

const SubText = styled.span`
  font-size: 12px;
  font-weight: 200;
  margin: 0px 10px;
  width: 100%;
`;

const AddAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  ${mobile({ flexDirection: "column" })}
`;

const AddAddressText = styled.button`
  font-size: 14px;
  font-weight: 200;
  margin: 0px 10px;
  cursor: pointer;
  padding: 5px;
  font-weight: 600;
`;

const ErrorTip = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 200;
  margin: 5px 10px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  ${mobile({ flexDirection: "column" })}
`;

const Button = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
  ${mobile({
    width: "100%",
    margin: "10px 0",
  })}

  &:hover {
    background-color: black;
    color: white;
  }
`;

const Whatstapp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  margin-top: 20px;
  border: 3px solid #000;
  cursor: pointer;
  ${mobile({ flexDirection: "column" })}
`;

const WhatsappText = styled.span`
  font-size: 14px;
  font-weight: 200;
  margin: 0px 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  padding: 0;
`;

const WhatsappImage = styled.img`
  width: 25px;
  height: 25px;
`;

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [address, setAddress] = useState("");
  const sidebarState = useSelector((state) => state.sidebar.open);
  const order = useSelector((state) => state.order);
  const isFetching = useSelector((state) => state.order.isFetching);
  const cart = useSelector((state) => state.cart);
  const quantity = useSelector((state) => state.cart.quantity);
  const user = useSelector((state) => state.user);
  const [submit, setSubmit] = useState(false);
  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const [t, i18n] = useTranslation("common");
  const language = useSelector((state) => state.store.language);
  const store = useSelector((state) => state.store.stores[0]);

  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };

  useEffect(() => {
    dispatch(resetOrder());
    dispatch(resetOrder());
  }, []);

  useEffect(() => {
    history.location.state &&
      history.location.state.fromCart &&
      cart.products.length > 0 &&
      handler();
  }, [history.location.state]);

  const encoreURI = (asset_url) => {
    // encode ( by %)
    return encodeURI(asset_url);
  };

  const addAddress = () => {
    closeHandler();
    setTimeout(() => {
      history.push({
        pathname: "/myaccount",
        state: {
          fromCart: true,
        },
      });
    }, 10);
  };

  const remove = (product) => {
    dispatch(deleteProduct(product));

    if (user.currentUser._id) {
      const newCart = cart.products.filter((item) => product._id !== item._id);
      updateCart(
        cart._id,
        newCart?.map((productItem) => {
          return {
            productId: productItem._id,
          };
        }),
        dispatch
      );
    }
  };

  const handleChange = (e) => {
    setAddress(e.target.value);
  };

  const openProduct = (product) => {
    history.push(`/product/${product.slug}`);
  };

  const handleCheckout = () => {
    if (!user.currentUser._id) {
      history.push({
        pathname: "/login",
        state: {
          fromCart: true,
        },
      });
    } else {
      cart?.products?.length > 0 && handler();
    }
  };

  const handleRemove = (item) => {
    dispatch(removeFromWishlist(item));
    if (user?.currentUser?._id) {
      // get user wishlist in variable
      const wishlist = user.currentUser.wishlist;

      const newWishlist = wishlist.filter(
        (product) => product._id !== item._id
      );
      updateUser(
        user.currentUser._id,
        { wishlist: newWishlist?.map((product) => product._id) },
        dispatch
      );
    }
  };

  const checkout = () => {
    setSubmit(true);
    if (address && !isFetching) {
      const products = cart?.products?.map((product) => {
        return {
          productId: product._id,
          quantity: product.quantity,
        };
      });

      const newAdr = user.currentUser.addresses.find(
        (addr) => addr._id === address
      );
      const order = {
        address: newAdr,
        products: products,
        userId: user.currentUser._id,
      };
      createOrder(cart._id, order, dispatch);
    }
  };

  const openWhatsapp = () => {
    window.open("https://api.whatsapp.com/send?phone=+22969051882");
  };

  return (
    <>
      <Container sidebarState={sidebarState}>
        <Helmet>
          <title>{t("cart.title")} - Laboratoire Luxe Cosmétique</title>
        </Helmet>
        <Wrapper>
          <Title>
            {path === "cart" ? t("cart.myCart") : t("cart.mywishlist")}
          </Title>
          <Top>
            <Link to="/">
              <TopButton>{t("cart.continue_shopping")}</TopButton>
            </Link>
            <TopTexts>
              <Link
                to="/cart"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <TopText>
                  {t("cart.cart")}({quantity})
                </TopText>
              </Link>
              <Link
                to="/wishlist"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <TopText>
                  {t("cart.wishlist")}(
                  {user.currentUser ? user?.currentUser?.wishlist?.length : 0})
                </TopText>
              </Link>
            </TopTexts>
            <TopButton
              type="filled"
              style={
                path === "cart"
                  ? { visibilithiddeny: "visible" }
                  : { visibility: "hidden" }
              }
              onClick={handleCheckout}
            >
              {t("cart.order_now")}
            </TopButton>
          </Top>
          <Bottom>
            <Info>
              {path !== "cart" &&
                user.currentUser.wishlist &&
                user?.currentUser?.wishlist?.length === 0 && (
                  <EmptyCart style={{ minHeight: "35vh" }}>
                    <EmptyCartText>{t("cart.empty_wishlist")}</EmptyCartText>
                  </EmptyCart>
                )}
              {path !== "cart" &&
                user.currentUser.wishlist &&
                user?.currentUser?.wishlist?.length !== 0 &&
                user?.currentUser?.wishlist.map((product) => (
                  <Product>
                    <ProductDetail
                      type={path}
                      onClick={() => openProduct(product)}
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        src={`${
                          process.env.REACT_APP_ASSETS_URL
                        }/products/${encoreURI(product.images[0])}`}
                      />
                      <Details>
                        <ProductName>
                          <b>{t("cart.product")} </b>{" "}
                          {product && language === "fr"
                            ? product.name
                            : product.en.name}
                        </ProductName>
                        {product?.range && (
                          <ProductBrand>
                            <b>{t("cart.range")} </b>{" "}
                            {product && language === "fr"
                              ? product.range
                              : product.range.en.name}
                          </ProductBrand>
                        )}
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductQuantityContainer>
                        <SummaryButton onClick={() => handleRemove(product)}>
                          {t("account.delete")}
                        </SummaryButton>
                      </ProductQuantityContainer>
                    </PriceDetail>
                  </Product>
                ))}
              {path === "cart" &&
                cart.products &&
                cart?.products?.length === 0 && (
                  <EmptyCart>
                    <EmptyCartText>{t("cart.empty_wishlist")}</EmptyCartText>
                  </EmptyCart>
                )}
              {path === "cart" &&
                cart.products &&
                cart?.products?.length !== 0 &&
                cart?.products?.map((product) => (
                  <Product>
                    <ProductDetail
                      type={path}
                      onClick={() => openProduct(product)}
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        src={`${
                          process.env.REACT_APP_ASSETS_URL
                        }/products/${encoreURI(product.images[0])}`}
                      />
                      <Details>
                        <ProductName>
                          <b>{t("cart.product")} </b>{" "}
                          {product && language === "fr"
                            ? product.name
                            : product.en.name}
                        </ProductName>
                        {product?.range && (
                          <ProductBrand>
                            <b>{t("cart.range")} </b>{" "}
                            {product && language === "fr"
                              ? product.range.name
                              : product.range.en.name}
                          </ProductBrand>
                        )}
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductQuantityContainer>
                        <MdRemove
                          onClick={() => remove(product)}
                          alt="supprimer"
                          style={{ fontSize: 24, cursor: "pointer" }}
                        />
                      </ProductQuantityContainer>
                    </PriceDetail>
                  </Product>
                ))}
              <Hr />
            </Info>
            {path === "cart" && (
              <Summary>
                <SummaryTitle>{t("cart.summary.title")}</SummaryTitle>
                {/* <SummaryItem>
              <SummaryItemText>Sous-total</SummaryItemText>
              <SummaryItemPrice>{cart.total} XOF</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Expédition estimée</SummaryItemText>
              <SummaryItemPrice>- 5 XOF</SummaryItemPrice>
            </SummaryItem>
            <SummaryItem>
              <SummaryItemText>Remise sur l'expédition</SummaryItemText>
              <SummaryItemPrice>-5 XOF</SummaryItemPrice>
            </SummaryItem> */}
                <SummaryItem type="total">
                  <SummaryItemText>{t("cart.summary.total")}</SummaryItemText>
                  <SummaryItemPrice>{quantity}</SummaryItemPrice>
                </SummaryItem>
                <SummaryButton onClick={handleCheckout}>
                  {t("cart.order_now")}
                </SummaryButton>
              </Summary>
            )}
          </Bottom>
        </Wrapper>
        <Modal open={visible} onClose={closeHandler} noPadding>
          <Modal.Body>
            <ContainerCart>
              <Select
                name="address"
                onChange={handleChange}
                disabled={
                  (user?.currentUser &&
                    user?.currentUser?.addresses !== 0 &&
                    isFetching) ||
                  (!isFetching && order._id)
                }
              >
                <option value="">{t("cart.order.select_address")}</option>
                {user?.currentUser?.addresses &&
                  user?.currentUser?.addresses.map((address) => (
                    <option value={address._id}>
                      {address.street}, {address.state}, {address.zip}
                    </option>
                  ))}
              </Select>
              {user?.currentUser?.addresses?.length !== 0 &&
                submit &&
                !isFetching &&
                !order._id &&
                !address && <ErrorTip>{t("cart.order.select")}</ErrorTip>}
              {user?.currentUser?.addresses?.length !== 0 && (
                <SubText>{t("cart.order.address_usage")}</SubText>
              )}
              {user?.currentUser?.addresses?.length === 0 && address === "" && (
                <ErrorTip>{t("cart.order.empty")}</ErrorTip>
              )}
              {user?.currentUser?.addresses?.length === 0 && (
                <AddAddress>
                  <AddAddressText onClick={addAddress}>
                    {t("account.addresses.add")}
                  </AddAddressText>
                </AddAddress>
              )}

              {isFetching &&
                user?.currentUser?.addresses?.length !== 0 &&
                !order._id && (
                  <LoadingWrapper>
                    <Loading color="warning" size="xl" type="gradient" />
                  </LoadingWrapper>
                )}
              {!isFetching &&
                !order._id &&
                user?.currentUser?.addresses?.length !== 0 && (
                  <ButtonContainer>
                    <Button onClick={checkout}>{t("cart.confirm")}</Button>
                  </ButtonContainer>
                )}

              {!isFetching && order._id && (
                <Text style={{ marginTop: "1rem" }}>
                  {t("cart.order.success")}
                </Text>
              )}

              {!isFetching && order._id && (
                <Whatstapp onClick={openWhatsapp}>
                  <WhatsappImage src={WhatsappImg} alt="Whatsapp" />
                  <WhatsappText>
                    <b>{store?.phones[0]}</b>
                  </WhatsappText>
                </Whatstapp>
              )}
            </ContainerCart>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default Cart;
